import React, { useState } from 'react';

import Layout from '../../../components/Layout';

const Pricing = () => {
  const [info, setInfo] = useState({
    program: '',
    fullName: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });

  const onSubmit = async () => {
    return;
  };

  return (
    <Layout fullMenu logoUrl="/NM/engineering">
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Pricing</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <h2>Course 1 (2PDH) : $20</h2>
            <h2>Entire Program (5PDH) : $40</h2>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default Pricing;
